<template>
  <div id="ManageSupplier">
    <v-container>
      <v-card class="secondary px-10 py-5">
        <v-card-title class="headline mb-5">Pembayaran</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="supplierSelect"
                  :items="supplierList"
                  item-value="supplier_id"
                  label="Nama Supplier"
                  clearable
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.supplier_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.supplier_name }} ({{
                      data.item.notification_count
                    }})
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="mitraSelect"
                  :items="mitraList"
                  item-text="store_name"
                  item-value="store_id"
                  label="Nama Toko"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-autocomplete
                  v-model="statusSelect"
                  :items="statusList"
                  item-text="status_name"
                  item-value="status_id"
                  label="Status"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="Dari Tanggal*"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      :rules="fromDateRules"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="Sampai Tanggal*"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      :rules="toDateRules"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="8" align="right"
              ><v-btn
                color="primary"
                @click="getData"
                class="px-10"
                :disabled="loading"
                :loading="loading"
                >Cari</v-btn
              ></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>

      <div v-if="isFound" class="mt-10">
        <payment-list
          :getPaymentsFilter="getPayments"
          :getFormFilter="formTemp"
        ></payment-list>
      </div>
    </v-container>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";
import PaymentList from "@/components/Widget/admin/PaymentList";
import PaymentService from "@/services/PaymentService";

export default {
  components: {
    PaymentList
  },

  data: () => ({
    supplierSelect: "",
    supplierList: [],
    mitraSelect: "",
    mitraList: [],
    statusSelect: "",
    statusList: [],
    getPayments: [],
    formTemp: null,
    isFound: false,
    fromDate: "",
    toDate: "",
    menuFromDate: false,
    menuToDate: false,
    valid: true,
    loading: false,
    fromDateRules: [v => !!v || "Dari Tanggal wajib diisi"],
    toDateRules: [v => !!v || "Sampai Tanggal wajib diisi"]
  }),

  mounted() {
    this.getMitra();
    this.getSupplier();
    this.getStatus();
  },

  methods: {
    getSupplier() {
      CommonService.getSupplierWithNotification()
        .then(response => {
          this.supplierList = response.data.suppliers;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getMitra() {
      CommonService.getMitra()
        .then(response => {
          this.mitraList = response.data.mitras;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getStatus() {
      CommonService.getStatus()
        .then(response => {
          this.statusList = response.data.statuses;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getData() {
      this.$refs.form.validate();
      this.loading = true;

      if (this.fromDate === "" || this.toDate === "") {
        return (this.loading = false);
      }

      const payload = {
        supplier_id: this.supplierSelect?.toString(),
        store_id: this.mitraSelect,
        status_id: this.statusSelect,
        start_date: this.fromDate,
        end_date: this.toDate
      };

      this.formTemp = payload;

      PaymentService.getData(payload)
        .then(response => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error"
            });
            this.getPayments = [];
          } else {
            this.isFound = true;
            this.getPayments = [];

            for (let i = 0; i < response.data.payments.length; i++) {
              this.getPayments.push({
                id: response.data.payments[i].id,
                po_number: response.data.payments[i].po_number,
                total_price: response.data.payments[i].total_price,
                payment_method: response.data.payments[i].payment_method,
                store_name: response.data.payments[i].store_name,
                supplier_name: response.data.payments[i].supplier_name,
                status: response.data.payments[i].status,
                order_date: response.data.payments[i].order_date,
                flag_important: response.data.payments[i].flag_important
              });
            }
          }
        })
        .catch(() => {
          this.isFound = false;

          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
