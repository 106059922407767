<template>
  <div>
    <v-card class="mt-10">
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Daftar Pembayaran</h5>
        </v-toolbar-title>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="getPaymentsFilter"
            class="elevation-0"
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr :class="isImportant(props.item.flag_important)">
                <td class="detailLink" @click="getDetail(props.item.id)">
                  {{ props.item.po_number }}
                </td>
                <td>{{ formatRupiah(props.item.total_price) }}</td>
                <td>{{ props.item.payment_method }}</td>
                <td>
                  {{ props.item.store_name }}
                </td>
                <td>
                  {{ props.item.supplier_name }}
                </td>
                <td>{{ props.item.status }}</td>
                <td>{{ props.item.order_date }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1000">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Nomor PO: {{ dataDetail.poNumber }}</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <h5>Status: {{ dataDetail.status }}</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pa-0">
          <div class="pa-5">
            <v-row>
              <v-col class="col-3">
                Nama Toko
              </v-col>
              <v-col class="col-9"> :&ensp; {{ dataDetail.storeName }} </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                Nominal
              </v-col>
              <v-col class="col-9">
                :&ensp; {{ formatRupiah(dataDetail.totalPrice) }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                Metode Pembayaran
              </v-col>
              <v-col class="col-9"> :&ensp; {{ dataDetail.method }} </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                Nomor Telepon Toko
              </v-col>
              <v-col class="col-9">
                :&ensp; {{ dataDetail.storePhoneNumber }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-space-around mt-10">
              <v-col class="col-12 col-md-5">
                <v-card>
                  <v-card-text>
                    <div>Rekening Pemesan</div>
                    <div class="text--primary text-h6 mt-3">
                      {{ dataDetail.fromAccount }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="col-12 col-md-5">
                <v-card>
                  <v-card-text>
                    <div>Rekening yang dituju</div>
                    <div class="text--primary text-h6 mt-3">
                      {{ dataDetail.toAccount }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center mt-10 ">
              <v-col class="col-12 col-md-6">
                <v-card>
                  <v-card-text>
                    <div>Bukti Pembayaran</div>
                    <div class="mt-5">
                      <!-- <v-img
                        class="elevation-6"
                        alt
                        :src="dataDetail.urlImage"
                        width="500px"
                      ></v-img> -->
                      <expandable-image
                        :src="dataDetail.urlImage"
                        width="500px"
                        close-on-background-click
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-10" v-if="isReasonReject">
              <v-col class="col-3">Alasan</v-col>
              <v-col class="col-9">
                <v-textarea
                  v-model="dataDetail.reasonMessage"
                  :disabled="disabledMessage"
                  outlined
                  counter
                  no-resize
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-card-actions v-if="flagButton">
              <v-container>
                <div class="d-flex justify-end mt-3">
                  <v-btn
                    color="success"
                    class="mr-5"
                    @click="approvePayment()"
                    :loading="loadingApprove"
                    :disabled="disabledApprove"
                    >Terima</v-btn
                  >
                  <v-btn
                    color="error"
                    @click="rejectPayment()"
                    :loading="loadingReject"
                    :disabled="disabledReject"
                    >Tolak</v-btn
                  >
                </div>
              </v-container>
            </v-card-actions>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import globalMixin from "@/mixins/globalMixin.js";

export default {
  props: ["getPaymentsFilter", "getFormFilter"],
  mixins: [globalMixin],

  data() {
    return {
      id: "",
      loadingApprove: false,
      disabledApprove: false,
      loadingReject: false,
      disabledReject: false,
      dialog: false,
      isReasonReject: false,
      disabledMessage: false,
      headers: [
        { text: "Nomor PO", sortable: false },
        { text: "Nominal Pembayaran", sortable: false },
        { text: "Metode Pembayaran", sortable: false },
        { text: "Nama Pemesan", sortable: false },
        { text: "Nama Supplier", sortable: false },
        { text: "Status", sortable: false },
        { text: "Tanggal Pemesanan", sortable: false }
      ],
      flagButton: false,
      dataDetail: {
        poNumber: "",
        status: "",
        totalPrice: "",
        method: "",
        storePhoneNumber: "",
        fromAccount: "",
        toAccount: "",
        urlImage: null,
        reasonMessage: "",
        storeName: ""
      }
    };
  },

  methods: {
    getDetail(id) {
      this.isReasonReject = false;
      this.flagButton = false;

      this.$root.$loaderModal.start("Loading...");

      const payload = {
        id: id
      };

      PaymentService.getDetail(payload)
        .then(response => {
          this.id = response.data.payment.id;
          this.flagButton = response.data.payment.flag_action;
          this.dataDetail.poNumber = response.data.payment.po_number;
          this.dataDetail.status = response.data.payment.status;
          this.dataDetail.totalPrice = response.data.payment.total_price;
          this.dataDetail.method = response.data.payment.payment_method;
          this.dataDetail.storeName = response.data.payment.store_name;
          this.dataDetail.storePhoneNumber =
            response.data.payment.user_phone_no;
          this.dataDetail.fromAccount =
            response.data.payment.user_account[0] === undefined
              ? "-"
              : response.data.payment.user_account[0].account_name +
                " / " +
                response.data.payment.user_account[0].account_number +
                " (" +
                response.data.payment.user_account[0].bank_name +
                ") ";
          this.dataDetail.toAccount =
            response.data.payment.user_account[0] === undefined
              ? "-"
              : response.data.payment.company_account[0].account_name +
                " / " +
                response.data.payment.company_account[0].account_number +
                " (" +
                response.data.payment.company_account[0].bank_name +
                ") ";
          this.dataDetail.urlImage = response.data.payment.img_url;
          this.dataDetail.reasonMessage = response.data.payment.reason_msg;

          if (this.dataDetail.reasonMessage != "") {
            this.isReasonReject = true;
            this.disabledMessage = true;
          }

          this.dialog = true;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    approvePayment() {
      this.loadingApprove = true;
      this.disabledReject = true;
      this.disabledApprove = true;

      const payload = {
        id: this.id
      };

      PaymentService.approve(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "success"
          });

          this.dialog = false;
          this.getData();
          this.$root.$emit("updateNotifAdmin");
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingApprove = false;
          this.disabledReject = false;
          this.disabledApprove = false;
        });
    },
    rejectPayment() {
      this.isReasonReject = true;

      this.loadingReject = true;
      this.disabledReject = true;
      this.disabledApprove = true;

      if (this.dataDetail.reasonMessage === "") {
        this.loadingReject = false;
        this.disabledReject = false;
        this.disabledApprove = false;

        this.$tostini({
          message: "Alasan Wajib Diisi!",
          type: "error"
        });

        return;
      }

      const payload = {
        id: this.id,
        reason_message: this.dataDetail.reasonMessage
      };

      PaymentService.reject(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "success"
          });

          this.dialog = false;
          this.getData();
          this.$root.$emit("updateNotifAdmin");
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingReject = false;
          this.disabledReject = false;
          this.disabledApprove = false;
        });
    },
    getData() {
      PaymentService.getData(this.getFormFilter)
        .then(response => {
          this.getPaymentsFilter = [];

          for (let i = 0; i < response.data.payments.length; i++) {
            this.getPaymentsFilter.push({
              id: response.data.payments[i].id,
              po_number: response.data.payments[i].po_number,
              total_price: response.data.payments[i].total_price,
              payment_method: response.data.payments[i].payment_method,
              user_account:
                response.data.payments[i].user_account[0] === undefined
                  ? "-"
                  : response.data.payments[i].user_account[0].account_name +
                    " / " +
                    response.data.payments[i].user_account[0].account_number +
                    " (" +
                    response.data.payments[i].user_account[0].bank_name +
                    ") ",
              company_account:
                response.data.payments[i].user_account[0] === undefined
                  ? "-"
                  : response.data.payments[i].company_account[0].account_name +
                    " / " +
                    response.data.payments[i].company_account[0]
                      .account_number +
                    " (" +
                    response.data.payments[i].company_account[0].bank_name +
                    ") ",
              status: response.data.payments[i].status,
              order_date: response.data.payments[i].order_date,
              flag_important: response.data.payments[i].flag_important
            });
          }
        })
        .catch(() => {
          this.isFound = false;

          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    isImportant: function(flag) {
      return flag ? "important" : "";
    }
  }
};
</script>
