const globalMixin = {
  data() {
    return {
      currentMonth: ""
    };
  },

  methods: {
    getDate(date = "") {
      if (date) {
        const split = date.toString().split(" ");
        return split[0];
      }
    },

    formatRupiah: function(respNumber) {
      const number = respNumber !== undefined ? respNumber.toString() : "0";
      const minFlag = number.charAt(0) === "-" ? true : false;

      let newNumber =
        number.charAt(0) === "-" ? number.replace("-", "") : number;

      let split = newNumber.split(",");
      let rest = split[0].length % 3;
      let rupiah = split[0].substring(0, rest);
      let thousand = split[0].substring(rest).match(/\d{3}/gi);

      if (thousand) {
        let separator = rest ? "." : "";
        rupiah += separator + thousand.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;

      return minFlag ? `-Rp. ${rupiah}` : `Rp. ${rupiah}`;
    },

    formatAmount: function(respNumber) {
      const number = respNumber !== undefined ? respNumber.toString() : "0";
      const minFlag = number.charAt(0) === "-" ? true : false;

      let newNumber =
        number.charAt(0) === "-" ? number.replace("-", "") : number;

      let split = newNumber.split(",");
      let rest = split[0].length % 3;
      let rupiah = split[0].substring(0, rest);
      let thousand = split[0].substring(rest).match(/\d{3}/gi);

      if (thousand) {
        let separator = rest ? "." : "";
        rupiah += separator + thousand.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;

      return minFlag ? `-${rupiah}` : rupiah;
    },

    getCurrentMonth() {
      const today = new Date();
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember"
      ];

      this.currentMonth = months[today.getMonth()];
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    convertMinutes(num) {
      if (num) {
        if (num != "-1") {
          const d = Math.floor(num / 1440); // 60*24
          const h = Math.floor((num - d * 1440) / 60);
          const m = Math.round(num % 60);

          return d > 0
            ? `${d} Hari ${h} Jam ${m} Menit`
            : `${h} Jam ${m} Menit`;
        }

        return "-";
      }

      return "-";
    }
  }
};

export default globalMixin;
