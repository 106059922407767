import api from "@/services/ApiService";

export default {
  getData(params) {
    return api().post(`/admin/payment/get_payment`, params);
  },
  getDetail(id) {
    return api().post(`/admin/payment/get_payment_detail`, id);
  },
  approve(params) {
    return api().post(`/admin/payment/approve_payment`, params);
  },
  reject(params) {
    return api().post(`/admin/payment/reject_payment`, params);
  }
};
